<template>
  <div id="menu_wrap" class="d-flex justify-center" style="width: 100%">
    <!-- menu mobile -->
    <!-- hamburgert button -->
    <v-btn
      @click.prevent="mobile_show_now"
      style="position: relative; top: 3px"
      light
      class="d-flex d-sm-none bluebar"
    >
      <v-icon color="white" x-large> $menu </v-icon>
    </v-btn>
    <v-list
      color="bluebar "
      v-if="mobile_show == true"
      style="position: absolute; top: 111px; left: 13px; z-index: 5000"
    >
      <v-list-group
        v-for="header in menu_headers"
        :key="header.id"
        :id="header.id"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              <span class="white--text" v-html="header.title"></span>
              <v-icon v-if="header.submenu.length > 0" color="yellow" small>
                $expand
              </v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          style="background-color: #224554"
          v-for="item in header.submenu"
          :key="item.id"
        >
          <v-list-item-content>
            <v-list-item-title class="white--text">
              <span v-html="item.title"></span
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <!-- end mobile menu -->

    <!-- menu desktop -->

    <div class="d-flex justify-space-center" style="width: 85%">
      <div
        class="d-none d-sm-flex"
        v-for="header in menu_headers"
        :id="header.id"
        :key="header.id"
        style="width: 16.7%"
      >
        <div
          :id="'innder_' + header.id"
          class="d-flex flex-row justify-start"
          style="width: 100%"
        >
          <v-menu offset-y id="quickstart">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                id="btn_quickstart"
                height="17"
                color="white"
                elevation="0"
                text
                class="white--text"
                v-bind="attrs"
                v-on="on"
              >
                <span v-html="header.title"></span>
                <!-- <span v-html="ateax"></span> -->
                <v-icon v-if="header.submenu.length > 0" color="yellow" small>
                  $expand
                </v-icon>
              </v-btn>
            </template>
            <v-list
              v-if="header.submenu.length > 0"
              color="bluebar"
              class="white--text"
            >
              <v-list-item v-for="item in header.submenu" :key="item.id">
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <span v-html="item.title"></span>
                    <!-- <span>{{ item.title }}</span> -->
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <!-- <v-list
            v-if="header.submenu.length == 0"
            color="bluebar"
            class="white--text"
          >
            <v-list-item>
              <v-list-item-title class="white--text">
                NO MENU
              </v-list-item-title>
            </v-list-item>
          </v-list> -->
          </v-menu>
          <div id="verty" class="vertical-line">|</div>
        </div>
      </div>
    </div>
  </div>

  <!-- end menu 1 -->
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 25px !important;
}
test {
  color: #224554;
}
.no-und {
  text-decoration: none !important;
}
.v-list .v-list-item {
  border-bottom: 2px solid gray;
}
.v-list .v-list-item:nth-last-child(0) {
  border-bottom: none;
}
div.vertical-line {
  // width: 2px;
  color: white;
  // height: 8px;
  // background-color: white;
}

.theme--dark.v-divider {
  border-color: gray;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 16px 4px !important;
  position: relative;
  top: -3px;

  font-weight: 400;
}

.ambertext {
  color: #ffc107 !important;
}
span.und {
  text-decoration: underline;
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
export default {
  name: "rpd_menu",
  mixins: [bus_common],
  data: () => ({
    mobile_show: false,

    menu_headers: [
      {
        id: "quick_start",
        title: `<span>QUICK <u>L</u>INKS</span>`,
        submenu: [
          {
            id: "Start Here",
            title: `<a title="Start Here" style="color:#ffc107;text-decoration:none;" 
            accesskey="s" href="https://www.renopd.com/starthere/"><span style="text-decoration:underline;">S</span>tart Here</a>`,
            submenu: [],
          },
          {
            id: "Home",
            title: `<a title="Home" style="color:white;text-decoration:none;"  
            accesskey="h" href="https://www.renopd.com/">
              <span style="text-decoration:underline;">H</span>ome</a>`,
            submenu: [],
          },
          {
            id: "join",
            title: `<a title="Join Our Team" style="color:white;text-decoration:none;"  
             href="https://www.renopd.com/Recruit" 
             target="_blank">Join Our Team</a>`,
            submenu: [],
          },
          {
            id: "faq",
            title: `<a title="Frequently Asked Questions" style="color:white;text-decoration:none;"   
             accesskey="q" href="https://www.renopd.com/FAQ">Frequently Asked 
             <span style="text-decoration:underline;">Q</span>uestions</a>`,
            submenu: [],
          },
          {
            id: "news",
            title: `<a title="Latest News" style="color:white;text-decoration:none;"  
            accesskey="n" href="https://www.renopd.com/news">Latest  
             <span style="text-decoration:underline;">N</span>ews</a>`,
            submenu: [],
          },

          {
            id: "secrete",
            title: `<a title="Secret Witness: opens in new window" style="color:white;text-decoration:none;"  
             accesskey="e" href="http://www.secretwitness.com/" 
             target="_blank">S<span style="text-decoration:underline;">e</span>cret Witness</a>`,
            submenu: [],
          },
          {
            id: "fndfrm",
            title: `<a title="Find a Form" style="color:white;text-decoration:none;"  
             accesskey="m" href="/form">Find a For<span style="text-decoration:underline;">m</span></a>`,
            submenu: [],
          },
        ],
      },
      {
        id: "report",
        title: `<span>FILE A <u>R</u>EPORT</span>`,
        submenu: [
          {
            id: "front_desk",
            title: `<a title="Front Desk" style="color:white;text-decoration:none;"
              target="_self"
             href="https://frontdesk.renopd.com/">Front <span style="text-decoration:underline;">D</span>esk</a>`,
            submenu: [],
          },
          {
            id: "new_report",
            title: `<a title="Start a New Report" style="color:white;text-decoration:none;"
             accesskey="r" role="menuitem" target="_self"
             href="https://www.renopd.com/report">Start a New <span style="text-decoration:underline;">R</span>eport</a>`,
            submenu: [],
          },
          {
            id: "update_report",
            title: `<a title="Update Existing report: opens in new tab" style="color:white;text-decoration:none;"
             accesskey="x" role="menuitem" 
             href="https://renopd.com/report" target="_self">Update Existing Report</a>`,
            submenu: [],
          },
          {
            id: "report_graffiti",
            title: `<a title="Report Graffiti" style="color:white;text-decoration:none;"
             accesskey="r" role="menuitem"
             href="https://renopd.com/report?type=graffiti">
             Report <span style="text-decoration:underline;">G</span>raffiti</a>`,
            submenu: [],
          },
          {
            id: "service_report",
            title: `<a title="Submit a Service Report: opens in new window" style="color:white;text-decoration:none;"
            role="menuitem" 
            href="https://www.reno.gov/community/reno-direct/report-an-issue"
             target="_blank">Submit a Service Report</a>`,
            submenu: [],
          },
          {
            id: "abandoned_vehicles",
            title: `<a title="Abandoned Vehicles" style="color:white;text-decoration:none;"
             accesskey="i" role="menuitem" 
             href="https://renopd.com/report?type=abandoned">
             Abandoned Veh<span style="text-decoration:underline;">i</span>cle</a>`,
            submenu: [],
          },
        ],
      },
      {
        id: "news",
        title: `<a accesskey="n" style="color:white;text-decoration:none;"  
        role="menuitem" href="https://www.renopd.com/news/" title="Latest News">LATEST <u>N</u>EWS</a>`,
        submenu: [],
      },
      {
        id: "resources",
        title: `<span>RESOUR<u>C</u>ES</span>`,
        submenu: [
          {
            id: "forms",
            title: `<a title="Forms"  style="color:white;text-decoration:none;"
            accesskey="m" role="menuitem"
             href="https://renopd.com/form" target="_self" >For<span style="text-decoration:underline;">m</span>s</a>`,
            submenu: [],
          },
          {
            id: "prevention",
            title: `<a title="Crime Prevention"  style="color:white;text-decoration:none;"
            role="menuitem" href="https://renopd.com/prevent" target="_self" >Crime Prevention</a>`,
            submenu: [],
          },
          {
            id: "station",
            title: `<a title="Station Locations" style="color:white;text-decoration:none;"  
             accesskey="o" role="menuitem"
              href="https://renopd.com/stations" >Station L<span style="text-decoration:underline;">o</span>cations</a>`,
            submenu: [],
          },
          {
            id: "parking",
            title: `<a  title="Pay parking tickets online: opens in new window" style="color:white;text-decoration:none;"  
             accesskey="p" role="menuitem" 
             href="https://parking.reno.gov/" target="_blank">Pay a Parking Ticket</a>`,
            submenu: [],
          },
          {
            id: "court_pay",
            title: `<a title="Online court payments: opens in new window" style="color:white;text-decoration:none;" 
            accesskey="t" role="menuitem"
             href="https://www.reno.gov/services/online-court-payments" target="_blank" >
             Cour<span style="text-decoration:underline;">t</span> Payments</a>`,
            submenu: [],
          },
          {
            id: "educate",
            title: `<a title="Court Education: opens in new window" style="color:white;text-decoration:none;" 
             role="menuitem"
             href="https://www.onlinecourteducation.com/rmc/" target="_blank">Court Education</a>`,
            submenu: [],
          },
          {
            id: "grants",
            title: `<a title="Grants: opens in new window" style="color:white;text-decoration:none;" 
          role="menuitem" 
             href="https://renopd.com/grants" target="_blank" >Grants</a>`,
            submenu: [],
          },
          {
            id: "policies",
            title: `<a title="Policies" style="color:white;text-decoration:none;" 
             accesskey="P" role="menuitem" style="color:white;text-decoration:none;" 
             href="https://renopd.com/generalorders" target="_self" ><span style="text-decoration:underline;">P</span>olicies</a>`,
            submenu: [],
          },
          {
            id: "alram",
            title: `<a title="Residential Alarm Info: opens in new window" style="color:white;text-decoration:none;" 
            role="menuitem" target="_blank" 
            href="https://www.cityalarmpermit.us/Pages/Home.html?CityId=OzRDUvRMEbw=" >Alarm Registration</a>`,
            submenu: [],
          },
        ],
      },
      {
        id: "divsions",
        title: `<span><span style="text-decoration:underline;">D</span>IVISIONS</span>`,
        submenu: [
          {
            id: "directory",
            title: `<a title="Directory"  style="color:white;text-decoration:none;" 
             accesskey="y" role="menuitem"
              href="https://renopd.com/alldiv" >Director<span style="text-decoration:underline;">y</span></a>`,
            submenu: [],
          },
          {
            id: "command",
            title: `<a title="Command" style="color:white;text-decoration:none;" 
            role="menuitem" href="https://renopd.com/staff" >Command</a>`,
            submenu: [],
          },
          {
            id: "records",
            title: `<a title="Records"  style="color:white;text-decoration:none;" 
             accesskey="j" role="menuitem" 
             href="https://renopd.com/records" ">Records</a>`,
            submenu: [],
          },
          {
            id: "frontdesk",
            title: `<a title="Front Desk"  style="color:white;text-decoration:none;" 
              role="menuitem"  
             href="https://frontdesk.renopd.com/">Front Desk</a>`,
            submenu: [],
          },
          {
            id: "traffic",
            title: `<a title="Traffic" style="color:white;text-decoration:none;" 
            href="https://renopd.com/traffic">Traffic</a>`,
            submenu: [],
          },
          {
            id: "ia",
            title: `<a title="Internal Affairs" style="color:white;text-decoration:none;" 
             accesskey="j" role="menuitem" 
             href="https://renopd.com/IA" >Internal Affairs</a>`,
            submenu: [],
          },
          {
            id: "detectives",
            title: `<a  title="Detectives" style="color:white;text-decoration:none;" 
            accesskey="b" role="menuitem" 
            href="https://renopd.com/detectives">Detectives</a>`,
            submenu: [],
          },
          {
            id: "vsu",
            title: `<a  title="Victim Services Unit"  style="color:white;text-decoration:none;" 
            accesskey="v" role="menuitem" 
            href="https://renopd.com/VSU" target="_blank">
            <span><span style="text-decoration:underline;">V</span>ictim Services Unit</a>`,
            submenu: [],
          },
          {
            id: "recruitment",
            title: `<a title="Recruitment" style="color:white;text-decoration:none;" 
             accesskey="k" role="menuitem"
              href="https://renopd.com/Recruit" >Recruitment</a>`,
            submenu: [],
          },
          {
            id: "evidence",
            title: `<a title="Evidence" style="color:white;text-decoration:none;" 
            href="https://renopd.com/Evidence" >Evidence</a>`,
            submenu: [],
          },
          {
            id: "firearms",
            title: `<a title="Firearms" style="color:white;text-decoration:none;" 
             href="https://renopd.com/firearms" >Firearms</a>`,
            submenu: [],
          },
        ],
      },
      {
        id: "contact",
        title: `<a accesskey="u" style="color:white;text-decoration:none;"  
        role="menuitem" href="https://www.renopd.com/alldiv/" title="Contact Us">
        CONTACT <span style="text-decoration:underline;">U</span>S</a>`,
        submenu: [],
      },
    ],
  }),
  methods: {
    mobile_show_now() {
      var self = this;
      this.mobile_show = true;
      // setTimeout(function () {
      //   self.mobile_show = false;
      // }, 10000);
    },

    message1() {
      alert("This button does nothing yet.");
    },
    init() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
